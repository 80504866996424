import {WarmeScholenConfig} from '../app/website-configuration/warme-scholen-config';
import {Tenant} from '../app/website-configuration/tenant';

export const environment = {
  production: false,
  preview: false,
  tenant: Tenant.WARME_SCHOLEN,
  apiUrl: 'https://api.acc.warmescholen.net',
  cmsApiUrl: 'https://cms.acc.warmvlaanderen.net/api',
  cmsApiVoorvoegsel: 'ws',
  applicationTitle: 'Warme Scholen',
  websiteConfiguratie: WarmeScholenConfig,
  matomoSiteId: '4',
  forumUrl: 'https://forum.acc.warmescholen.net',
  auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_jBbA3WooP',
    userPoolWebClientId: '6lahhtoh4fd2nifmma5kjjrc9s',
    cognitoDomain: 'https://warm-vlaanderen-acc-ws.auth.eu-central-1.amazoncognito.com'
  },
};
